import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ContactForm from '../components/Contact/ContactForm'
import TagsForAll from '../components/Common/TagsForAll'
import { Helmet } from 'react-helmet'

const Contact = () => {
    return (
        <Layout>
        <TagsForAll pageTitle="Contact Us" />
        <Helmet>
            <meta name='robots' content='index, follow' />
            <link rel="canonical" href="https://zaavia.net/contact" />
        </Helmet>
            <Navbar />
            <PageBanner
                pageTitle="Contact" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Contact" 
            />
            <ContactForm />
            <Footer />
        </Layout>
    );
}

export default Contact